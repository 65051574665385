<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="sims"
          sort-by="inserted_at"
          item-key="id"
          :headers="headers"
          :sort-desc="true"
          :provider="provider"
          show-select
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn dark color="error" small @click="deleteAll"> Delete </v-btn>
            <SimsDialog
              :edited-sim="editedSim"
              :selected-sims="reportStore.selectedItems"
              @clear-selected="reportStore.selectedItems = []"
              @update-sim-providers="getSimProviders"
              @update-bill-payers="getBillPayers"
            />
          </template>
          <template #item.actions="{ item }">
            <ERow justify="center" align="center">
              <!-- EDIT -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    x-small
                    v-on="on"
                    @click="editedSim = item"
                  >
                    <v-icon small> fa-pencil-alt </v-icon>
                  </v-btn>
                </template>
                <span>Edit the sim</span>
              </v-tooltip>
            </ERow>
          </template>
          <template #item.serialNumber="{ item }">
            <span v-if="item.serialNumber">
              {{ item.serialNumber }}
              <OpenInNewWindowButton
                :target-link="`${item.rmsUrl}`"
                hint="Open In RMS"
              />
            </span>
            <span v-else>-</span>
          </template>
          <template #item.number="{ item }">
            <span class="cursor-pointer primary--text" @click="simOps(item)">
              {{ item.number }}
            </span>
          </template>
          <template #item.cameras="{ item }">
            <CamerasColumn
              :item="item"
              @open-camera-dialog="
                (camera) => cameraDialogStore.openDialog({ camera })
              "
            />
          </template>
          <template #item.lastSmsAt="{ item }">
            <span>
              {{ formatDate(item.lastSmsAt) }}
            </span>
          </template>
          <template #item.lastSms="{ item }">
            <EReadMore :content="item.lastSms" :max-length="60" />
          </template>
          <template #item.status="{ item }">
            <span v-if="item.status == 'active'" class="green--text">
              Active
            </span>
            <span v-else-if="item.status == 'inactive'" class="red--text">
              Inactive
            </span>
            <span v-else>-</span>
          </template>
          <template #item.description="{ item }">
            <span>
              {{ item.description }}
            </span>
            <OpenInNewWindowButton
              v-if="isDescriptionLink(item.description)"
              :target-link="getDescriptionLink(item.description)"
              hint="Open link in new tab"
            />
          </template>
        </ReportTable>
        <SmsHistoryDialog
          v-model="showSimOps"
          :sim="selectedSimNumber"
          :selected-sim="selectedSim"
        />
      </ECol>
    </ERow>
  </v-container>
</template>

<script>
import SimsHeader from "@/components/sims/simsHeaders"
import SimsDialog from "@/components/sims/SimsDialog"
import SmsHistoryDialog from "@/components/sims/SmsHistoryDialog.vue"
import filterFields from "@/components/sims/simsSearchFilters"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import CamerasColumn from "@/components/CamerasColumn"
import ReportTable from "@/components/ReportTable"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: {
    ReportTable,
    SimsDialog,
    SmsHistoryDialog,
    OpenInNewWindowButton,
    CamerasColumn,
  },
  data: () => {
    return {
      editedSim: {},
      showSimOps: false,
      selectedSimNumber: "",
      selectedSim: null,
      headers: SimsHeader,
      simProviderList: [],
      billPayerList: [],
      routerTypes: [],
      filterFields: {},
    }
  },
  head() {
    return {
      title: "Admin - SIMs",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore, useCameraDialogStore),
    provider() {
      return AdminApi.sims.getSims
    },
  },
  async created() {
    await this.getRouterTypes()
    await this.getSimProviders()
    await this.getBillPayers()
    this.filterFields = filterFields(this)
  },
  methods: {
    async getRouterTypes() {
      try {
        this.routerTypes = await AdminApi.routers.getRouterTypes()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load router types!",
          error,
        })
      }
    },
    simOps(item) {
      this.selectedSimNumber = item?.number ? item.number : ""
      this.selectedSim = item ? item : null
      this.showSimOps = true
    },
    async deleteAll() {
      if (this.reportStore.selectedItems.length === 0) {
        this.$notifications.warn("At least select one sim!")
      } else {
        if (
          await this.$confirmDialog.open({
            title: "Delete sim(s) ?",
            message: "This will permanently delete selected sim(s)!",
          })
        ) {
          for (const sim of this.reportStore.selectedItems) {
            await this.deleteSim(sim)
            this.reportStore.items = this.reportStore.items.filter(
              (item) => item.id !== sim.id
            )
          }
          this.reportStore.selectedItems = []
          this.$notifications.success("SIM(s) deleted successfully.")
        }
      }
    },
    async deleteSim(item) {
      try {
        await AdminApi.sims.deleteSim(item.id)
      } catch (error) {
        this.$notifications.error({ text: "Delete sim failed", error })
      }
    },
    async getSimProviders(updateFilter = false) {
      try {
        this.simProviderList = await AdminApi.sims.getSimProviders()
        if (updateFilter) {
          this.filterFields = filterFields(this)
        }
      } catch (error) {
        this.$notifications.error({
          text: "Could not load sim providers!",
          error,
        })
      }
    },
    async getBillPayers(updateFilter = false) {
      try {
        this.billPayerList = await AdminApi.sims.getBillPayers()
        if (updateFilter) {
          this.filterFields = filterFields(this)
        }
      } catch (error) {
        this.$notifications.error({
          text: "Could not load bill payers!",
          error,
        })
      }
    },
    isDescriptionLink(description) {
      return description?.includes("http")
    },
    getDescriptionLink(description) {
      const urlRegex = /(https?:\/\/[^\s]+)/g
      const [url] = description?.match(urlRegex) || []

      return url
    },
  },
}
</script>
