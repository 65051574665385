export default [
  {
    value: "actions",
    text: "",
    width: 25,
    copyable: false,
  },
  {
    value: "number",
    text: "Number",
    width: 50,
    sortable: true,
    filterable: true,
  },
  {
    value: "simProvider",
    text: "SIM Provider",
    width: 120,
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "billPayer",
    text: "Bill Payer",
    width: 120,
    sortable: true,
    filterable: true,
  },

  {
    value: "pin1",
    text: "PIN 1",
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    value: "pin2",
    text: "PIN 2",
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    value: "puk",
    text: "PUK",
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    value: "iccid",
    text: "ICCID",
    width: 150,
    sortable: true,
    filterable: true,
  },
  {
    value: "description",
    text: "Description",
    width: 200,
    sortable: true,
    filterable: true,
  },

  {
    value: "cameras",
    text: "Cameras",
    width: 200,
    sortable: false,
    filterable: true,
    toStringFn: (item, key) => item[key].map((camera) => camera.name).join(","),
  },

  {
    value: "serialNumber",
    text: "Serial Number",
    width: 75,
    sortable: true,
    filterable: true,
  },
  {
    value: "status",
    text: "Status",
    width: 65,
    sortable: true,
    filterable: true,
    align: "center",
  },
  {
    value: "routerType",
    text: "Router Type",
    width: 100,
    sortable: true,
    filterable: true,
  },
  {
    value: "powerType",
    text: "Power Type",
    width: 75,
    sortable: true,
    filterable: true,
  },
  {
    value: "powerSchedule",
    text: "Power Schedule",
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    value: "lastSms",
    text: "Last SMS",
    width: 350,
    sortable: true,
    filterable: true,
  },
  {
    value: "lastSmsAt",
    text: "Last SMS At",
    width: 150,
    sortable: true,
    filterable: true,
  },
]
